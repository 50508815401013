import createSite from './createSite';
import editSite from './editSite';

import createPage from './createPage';
import editPage from './editPage';

import createArtist from './createArtist';
import editArtist from './editArtist';

import tourDates from './_tourDates';

const schemas = {
	sites: {
		create: createSite,
		default: editSite,
	},
	pages: {
		create: createPage,
		default: editPage,
	},
	artists: {
		create: createArtist,
		default: editArtist,
	},
	fieldSchemas: {
		tourDates,
	},
};

export default schemas;
