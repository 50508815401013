import React from "react";
// import update from 'react-addons-update';
import { Link } from "react-router";
import { userHasPermission } from "../helpers/helpers.js";
import { cNames } from "../helpers/helpers.js";

class SiteHome extends React.Component {
  constructor(props) {
    super(props);
    const site = this.props.sites.find(
      s => s.slug === this.props.params.slugOne
    );
    this.state = { site };
  }

  componentWillReceiveProps(nextProps) {
    const site = nextProps.sites.find(
      s => s.slug === this.props.params.slugOne
    );
    this.setState({ site });
  }

  renderPagesMenu() {
    const sitePages = this.state.site.pages.sort((a, b) => {
      if (a.slug < b.slug) return -1;
      if (a.slug > b.slug) return 1;
      return 0;
    });
    const pages = [];
    for (const page of sitePages) {
      const key = page.slug;
      const title = page.cmsTitle || page.name;
      const href = `/dashboard/sites/${this.props.params.slugOne}/pages/${
        page.slug
      }`;
      const pageLink = this.renderLink(key, href, title);
      pages.push(pageLink);
    }
    const createKey = "create";
    const createHref = `/dashboard/sites/${
      this.props.params.slugOne
    }/pages/create`;
    const createLabel = "+ add page";
    const create = this.renderLink(createKey, createHref, createLabel, {
      role: "dev",
      classNames: ["secondary"]
    });
    if (create) pages.push(create);

    return pages;
  }

  renderSettingsMenu() {
    const items = [];
    const homepage =
      this.state.site.pages.find(p => p.slug === this.state.site.homepage) ||
      this.state.site.pages[0];
    if (homepage) {
      const homepageItem = (
        <li key="homepage" className="info">
          Homepage: {homepage.cmsTitle}
        </li>
      );
      items.push(homepageItem);
    }

    const editHref = `/dashboard/sites/${this.props.params.slugOne}/settings`;
    const editLink = this.renderLink("settings", editHref, "~ edit", {
      classNames: ["secondary"]
    });
    items.push(editLink);

    return items;
  }

  renderLink(key, href, label, options = {}) {
    if (options.role && !userHasPermission(options.role, this.props.auth.user))
      return null;
    const className = cNames(options.classNames);
    return (
      <li className={className} key={key}>
        <Link to={href}>{label}</Link>
      </li>
    );
  }

  render() {
    return (
      <div className="menu">
        <h1>{this.state.site.cmsTitle}</h1>
        <div className="categories">
          <div className="category">
            <h4 className="category__title">Pages</h4>
            <ul>{this.renderPagesMenu()}</ul>
          </div>
          <div className="category">
            <h4 className="category__title">Site Settings</h4>
            <ul>{this.renderSettingsMenu()}</ul>
          </div>
        </div>
      </div>
    );
  }
}

SiteHome.propTypes = {
  params: React.PropTypes.object,
  sites: React.PropTypes.array
};

export default SiteHome;
