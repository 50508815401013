import axios from 'axios';
import cookie from 'react-cookie';

import config from '../config.js';

// const CLIENT_ROOT_URL = config.appRoot || window.location.origin;

export function logoutUser(router) {
	return (dispatch) => {
		dispatch({ type: 'unauth_user' });
		cookie.remove('token', { path: '/' });
		router.push('/');
	};
}

export function errorHandler(dispatch, error, type, router) {
	if (!error) return false;
	let errorMessage = '';
	if (error.data) {
		errorMessage = (error.data.message) ? error.data.message : error.data;
	} else {
		errorMessage = error;
	}
	if (error.status === 401) {
		const message = errorMessage || error.message || 'You are not authorized to do this. Please login and try again.';
		dispatch({
			type,
			payload: {
				status: error.status,
				message,
			},
		});
		logoutUser(router);
	} else {
		dispatch({
			type,
			payload: errorMessage,
		});
	}
	return false;
}

export function loginUser({ email, password }, router) {
	return (dispatch) => {
		axios.post(`${config.apiRoot}/auth/login`, { email, password })
		.then(response => {
			cookie.save('token', response.data.token, { path: '/' });
			cookie.save('user', response.data.user, { path: '/' });
			dispatch({ payload: response.data.user, type: 'auth_user' });
			router.push('/dashboard');
		})
		.catch((error) => {
			errorHandler(dispatch, error.response, 'auth_error');
		});
	};
}

export function registerUser({ email, name, username, password, password2 }, router) {
	return (dispatch) => {
		axios.post(`${config.apiRoot}/auth/register`, { email, name, username, password, password2 })
		.then(response => {
			cookie.save('token', response.data.token, { path: '/' });
			dispatch({ payload: response.data.user, type: 'auth_user' });
			router.push('/dashboard');
		})
		.catch((error) => {
			errorHandler(dispatch, error.response, 'auth_error');
		});
	};
}

export function protectedTest(router) {
	return (dispatch) => {
		axios.get(`${config.apiRoot}/auth/user`, {
			headers: { Authorization: cookie.load('token') },
		})
		.then(response => {
			cookie.save('token', response.data.token, { path: '/' });
			cookie.save('user', response.data.user, { path: '/' });
			dispatch({ payload: response.data.user, type: 'auth_user' });
			dispatch({
				type: 'protected_test',
				payload: response.data.content,
			});
		})
		.catch((error) => {
			// const error = err.response || err;
			// console.log(err, err.response);
			console.log(error, error.response, error.message);
			errorHandler(dispatch, error.response, 'auth_error', router);
		});
	};
}
