const backgrounds = [
	'baauer.jpg',
	'bwana.jpg',
	'eclair.jpg',
	'lba.jpg',
	'naked.jpg',
	'pec.jpg',
	'soraya.jpg',
];

export default backgrounds;