import React from 'react';
import { connect } from 'react-redux';
import { clearErrors } from '../actions/errors';


class ErrorMessage extends React.Component {

	constructor(props) {
		super(props);
		this.buildClass = this.buildClass.bind(this);
		this.onClick = this.onClick.bind(this);
		this.renderMessage = this.renderMessage.bind(this);
		this.state = { closed: false };
	}

	onClick() {
		this.setState({
			closed: true,
		});
		this.props.clearErrors();
		if (this.props.onClickHandler) this.props.onClickHandler();
	}

	buildClass() {
		let className = 'message';
		if (this.props.type === 'error') className += ' message--error';
		if (this.state.closed) className += ' message--closed';
		return className;
	}

	renderMessage(error, i) {
		const string = error.message;
		return (<p key={i}>{string}</p>);
	}

	render() {
		const className = this.buildClass();
		return (
			<div className={className}>
				<div className="message__inner modal">
					<h3 className="message__intro">Sorry,</h3>
					{this.props.errors.map(this.renderMessage)}
					<button type="button" onClick={this.onClick}>Ok.</button>
				</div>
			</div>
		);
	}
}

ErrorMessage.propTypes = {
	onClickHandler: React.PropTypes.func,
	clearErrors: React.PropTypes.func,
	errors: React.PropTypes.array,
	type: React.PropTypes.string,
};

ErrorMessage.contextTypes = {
	router: React.PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		sites: state.sites,
		errors: state.errors,
	};
}


export default connect(mapStateToProps, { clearErrors })(ErrorMessage);
