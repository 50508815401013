function sites(state = [], action) {
	const data = action.data;
	let siteIndex;
	let pageIndex;
	let site;
	let newState;
	switch (action.type) {
	case 'add_site':
		newState = [
			...state,
			action.payload,
		];
		newState.sort((a, b) => {
			if (a.slug < b.slug) return -1;
			if (a.slug > b.slug) return 1;
			return 0;
		});
		return newState;
	case 'fetch_sites':
		newState = action.payload.sites;
		return newState;

	case 'update_site':
		siteIndex = state.findIndex((stateSite) => stateSite.slug === action.payload.slug);
		newState = [
			...state.slice(0, siteIndex),
			action.payload,
			...state.slice(siteIndex + 1),
		];
		console.log(newState);
		return newState;

	// ****
	// Deprecated
	// *************
	case 'UPDATE_SITE':
		siteIndex = state.findIndex((stateSite) => stateSite.id === data.id);
		site = Object.assign({}, state[siteIndex]);
		Object.keys(data).map((key) => {
			site[key] = data[key];
			site.test = 'test';
		});
		newState = [
			...state.slice(0, siteIndex),
			site,
			...state.slice(siteIndex + 1),
		];
		return newState;

	case 'ADD_SITE':
		return [...state, action.data];

	case 'ADD_PAGE':
		siteIndex = state.findIndex((stateSite) => {
			return stateSite.id === parseInt(data.site_id, 10);
		});
		site = Object.assign({}, state[siteIndex]);
		site.pages.push(data);
		newState = [
			...state.slice(0, siteIndex),
			site,
			...state.slice(siteIndex + 1),
		];
		return newState;

	case 'UPDATE_PAGE':
		siteIndex = state.findIndex((stateSite) => stateSite.id === data.site_id);
		site = Object.assign({}, state[siteIndex]);
		pageIndex = site.pages.findIndex((statePage) => statePage.id === data.id);
		// page = site.pages[pageIndex];

		site.pages = [
			...site.pages.slice(0, pageIndex),
			data,
			...site.pages.slice(pageIndex + 1),
		];

		newState = [
			...state.slice(0, siteIndex),
			site,
			...state.slice(siteIndex + 1),
		];
		return newState;
	case 'REMOVE_SITE':
		return state;
	default:
		return state;
	}
}

export default sites;
