
export const AUTH_USER = 'auth_user';
export const UNAUTH_USER = 'unauth_user';
export const AUTH_ERROR = 'auth_error';
export const FORGOT_PASSWORD_REQUEST = 'forgot_password_request';
export const RESET_PASSWORD_REQUEST = 'reset_password_request';

export const FETCH_SITES = 'fetch_sites';
export const FETCH_SITE = 'fetch_site';
export const ADD_SITE = 'add_site';
export const UPDATE_SITE = 'update_site';
export const REMOVE_SITE = 'remove_site';
export const SITE_ERROR = 'site_error';

export const FETCH_ARTISTS = 'fetch_artists';
export const FETCH_ARTIST = 'fetch_artst';
export const ADD_ARTIST = 'add_artist';
export const UPDATE_ARTIST = 'update_artist';
export const REMOVE_ARTIST = 'remove_artist';
export const ARTIST_ERROR = 'artist_error';

export const ADD_PAGE = 'add_page';
export const UPDATE_PAGE = 'update_page';
export const REMOVE_PAGE = 'remove_page';
