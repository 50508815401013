const createArtist = {

	title: 'Add Artist',

	fieldsets: [
		{
			id: 'artist',
			label: 'Configuration',
			config: {
				rootObject: true,
				method: 'post',
				endpoint: '/api/artists/add',
				action: 'createArtist',
				redirect: '/',
			},
			fields: [
				{
					id: 'name',
					label: 'Name',
					role: 'admin',
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'slug',
					label: 'Slug',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'id',
					label: 'id#',
					classNames: ['field--half'],
					options: {
						readOnly: true,
					},
				},
				{
					id: 'contentSchema',
					label: 'JSON Schema',
					role: 'dev',
					type: 'code',
					classNames: ['field--large'],
					validate: {
						json: true,
					},
				},
			],
		},
	],
};

export default createArtist;
