import React, { Component } from 'react';
import { connect } from 'react-redux';
import { protectedTest, logoutUser } from '../../actions/auth';

export default (ComposedComponent) => {
	class Authentication extends Component {
		constructor(props) {
			super(props);
			this.authFailedHandler = this.authFailedHandler.bind(this);
		}

		componentWillMount() {
			this.props.protectedTest(this.conte);
			// if (!this.props.auth || this.props.auth === undefined) {
			// 	this.context.router.push('/');
			// } else if (!this.props.auth.authenticated) {
			// 	this.context.router.push('/');
			// }
		}

		componentWillUpdate(nextProps) {
			if (!nextProps.auth.authenticated) {
				this.authFailedHandler()
			}
			// this.props.protectedTest(this.authFailedHandler);
			// if (!nextProps.auth) {
			// 	this.context.router.push('/');
			// } else if (!nextProps.auth.authenticated) {
			// 	this.context.router.push('/');
			// }
		}

		authFailedHandler() {
			this.props.logoutUser(this.context.router);
			// this.context.router.push('/');
		}

		render() {
			return <ComposedComponent {...this.props} />;
		}
	}

	function mapStateToProps(state) {
		return {
			auth: state.auth,
			errors: state.errors,
		};
	}

	Authentication.propTypes = {
		auth: React.PropTypes.object,
		protectedTest: React.PropTypes.func,
		logoutUser: React.PropTypes.func
	};

	Authentication.contextTypes = {
		router: React.PropTypes.object,
	};

	return connect(mapStateToProps, { protectedTest, logoutUser })(Authentication);
};
