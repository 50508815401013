import React from 'react';

export class Thumbnail extends React.Component {

	constructor(props) {
		super(props);
		this.onImageError = this.onImageError.bind(this);
		const src = (this.props.src === undefined) ? '' : this.props.src;
		this.state = {
			src,
		};
	}

	componentWillMount() {
		if (this.props.src === '') this.onImageError();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ src: nextProps.src });
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.src !== this.props.src) return true;
		if (nextProps.src === this.props.src) return false;
		return true;
	}

	onImageError() {
		this.setState({ src: '/images/image-not-found.jpg' });
	}

	render() {
		return (
			<figure className="field__image">
				<img src={this.state.src} alt={this.props.alt} onError={this.onImageError} />
			</figure>
			);
	}
}

Thumbnail.propTypes = {
	src: React.PropTypes.string,
	alt: React.PropTypes.string,
};


/*
// :::Select
// -------------------------------------------------- */

export class Select extends React.Component {

	constructor(props) {
		super(props);
		this.renderOptions = this.renderOptions.bind(this);
	}

	renderOptions() {
		const optionsArray = [];
		const options = Object.assign({}, this.props.field.options);
		if (this.props.field.config.required !== true) {
			options.disabled = '(none)';
		}
		Object.keys(options).map((key, index) => {
			const label = options[key];
			const value = key;
			// const disabled = (this.props.field.config.required == true && key == 'disabled');
			optionsArray.push(<option key={index} value={value}>{label}</option>);
		});

		return optionsArray;
	}

	render() {
		return (
			<select onChange={this.props.onChange} value={this.props.field.value || this.props.field.config.default}>
				{this.renderOptions()}
			</select>
		);
	}
}

Select.propTypes = {
	onChange: React.PropTypes.func,
	field: React.PropTypes.object,
	options: React.PropTypes.array,
};

/*
// :::CheckBox
// -------------------------------------------------- */

export class Checkbox extends React.Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.state = { checked: (this.props.value === 'on') };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			checked: (nextProps.value === 'on'),
		});
	}

	handleChange(e) {
		this.setState({
			checked: !this.state.checked,
		});
		const newValue = (this.state.checked) ? 'off' : 'on';
		this.props.onChange(newValue);
	}

	handleClick() {
		this.setState({
			checked: !this.state.checked,
		});
	}

	buildLabel() {
		// build in logic here to show 'label', 'label-on', or 'label-off';
		const label = (this.props.config) ? this.props.config.label : '';
		return <label htmlFor={this.props.id}>{label}</label>
	}

	render() {
		return (
			<div className="checkbox__container">
				<input type="checkbox" id={this.props.id} onChange={this.handleChange} checked={this.state.checked}></input>
				{this.buildLabel()}
			</div>
		);
	}
}


Checkbox.propTypes = {
	id: React.PropTypes.string,
	fieldIndex: React.PropTypes.number,
	onChange: React.PropTypes.func.isRequired,
	config: React.PropTypes.object,
	value: React.PropTypes.string,
};
