import React from 'react';
// import update from 'react-addons-update';
import { Link } from 'react-router';
import { userHasPermission } from '../helpers/helpers.js';
import backgrounds from './backgrounds.js';

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.renderSubMenus = this.renderSubMenus.bind(this);
		this.renderSiteMenus = this.renderSiteMenus.bind(this);
		this.renderTab = this.renderTab.bind(this);
		// this.renderPageMenus = this.renderPageMenus.bind(this);
		this.renderArtistMenus = this.renderArtistMenus.bind(this);
		this.setTopPage = this.setTopPage.bind(this);
		this.renderIfRole = this.renderIfRole.bind(this);

		const bgIndex = Math.floor(Math.random() * backgrounds.length);
		const bgSrc = `/images/backgrounds/${backgrounds[bgIndex]}`;
		// const preloadSrc = `/images/backgrounds/${backgrounds[bgIndex]}`;

		this.state = { bgIndex, bgSrc };
		// setInterval(() => {
		// 	let newBgIndex = this.state.bgIndex;
		// 	newBgIndex = (newBgIndex === backgrounds.length - 1) ? 0 : newBgIndex + 1;
		// 	const newSrc = `/images/backgrounds/${backgrounds[newBgIndex]}`;
		// 	this.setState({ bgIndex: newBgIndex, preloadSrc: newSrc });
		// 	setTimeout(() => this.setState({ bgSrc: newSrc }), 1000);
		// }, 5000);
	}

	componentWillMount() {
		this.setTopPage();
	}

	componentWillReceiveProps(newProps) {
		this.setTopPage(newProps.params);
	}

	setTopPage(params = this.props.params) {
		let slug = '';
		let tab = '';
		if (params.modelOne === 'sites') {
			slug = `site.${params.slugOne}`;
			tab = params.modelTwo
		}
		slug = (slug.length) ? slug : false;
		this.setState({
			topPage: slug,
			activeTab: tab,
		});
	}

	renderIfRole(role, string) {
		if (userHasPermission(role, this.props.user)) return string;
		return null;
	}

	renderSubMenus() {
		return null;
		const artists = this.props.artists;
		const sites = this.props.sites;
		const pages = [];
		for (const site of sites) {
			for (const sitePage of site.pages) {
				const newPage = Object.assign({}, sitePage);
				newPage.parentSite = site.slug;
				pages.push(newPage);
			}
		}

		const siteMenus = sites.map(this.renderSiteMenus);
		const artistMenus = artists.map(this.renderArtistMenus);
		// const pageMenus = pages.map(this.renderPageMenus);
		return [siteMenus, artistMenus];
	}

	renderSiteMenus(site, i) {
		const tabs = [];
		const settings = this.renderTab(site.slug, 'settings', 'Site Settings');	
		for (const page of site.pages) {
			tabs.push(this.renderTab(site.slug, `pages/${page.slug}`, page.cmsTitle));
		}
		tabs.push(this.renderTab(site.slug, 'createPage', '+ Add Page'));


		let className = 'submenu';
		if (this.state.topPage === `site.${site.slug}`) className += ' active';
		return (
			<div key={i} className={className}>
				<h2 className="submenu__title">{site.cmsTitle}</h2>
				<div className="submenu__section">
					{settings}<br/>
					{tabs}
				</div>
			</div>
			);
	}

	renderArtistMenus(artist, i) {
		let className = 'submenu';
		if (this.state.topPage === `artist.${artist.slug}`) className += ' active';
		return (
			<div key={i} className={className}>
				<h2 className="menu__title">{artist.name}</h2>
				<Link to={`/dashboard/artists/${artist.slug}/settings`}><h4>Settings</h4></Link>
			</div>
			);
	}

	renderTab(parent, slug, label) {
		// this is hacky and bad, the whole path thing. Sidebar & tab navigation should be rethought from the ground up
		let className = 'submenu__item';
		const path = this.props.routes[this.props.routes.length - 1].path;
		let active = this.state.activeTab;
		if (!active && path) active = path.split('/').pop();
		if (slug === active) className += ' active';
		if (slug === 'createPage') className += ' create-page';
		if (slug === 'settings') className += ' settings';
		return <h4 key={`${parent}-${slug}`} className={className} ><Link to={`/dashboard/sites/${parent}/${slug}`}>{label}</Link></h4>;
	}

	// renderTabs(tabs, parent) {
	// 	const tabsArr = [];
	// 	for (const tab of tabs) {
	// 		let className = 'menu__tab';
	// 		if (tab.slug && tab.slug === this.state.activeTab) className += ' active';
	// 		tabsArr.push(<Link key={tab.slug} className={className} to={`/dashboard/sites/${parent}/${tab.slug}`}><h4>{tab.cmsTitle}</h4></Link>);
	// 	}

	// 	return tabsArr;
	// }

	render() {
		const divStyle = { backgroundImage: `url('${this.state.bgSrc}')` };
		return (
			<nav className="">
				<Link className="luckyme-logo" to="/dashboard"><img src="/images/luckyme-logo.png" alt="" />{this.state.test}</Link>
				<div className="menu__inner">
					{this.renderSubMenus()}
				</div>
				<div className="user">
					<h4 className="user__name">{this.props.auth.user.username}</h4>
					<a href="/logout"><h4 className="logout">logout</h4></a>
				</div>
			</nav>
		);
	}
}

Sidebar.propTypes = {
	routes: React.PropTypes.array,
	params: React.PropTypes.object,
	auth: React.PropTypes.object,
	sites: React.PropTypes.array,
	artists: React.PropTypes.array,
	pages: React.PropTypes.array,
};

export default Sidebar;
