import React from 'react';
import { connect } from 'react-redux';

import Sidebar from './Sidebar.js';

import { fetchSites } from '../actions/sites';
import { fetchArtists } from '../actions/artists';

class Home extends React.Component {

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.fetchSites();
		// this.props.fetchArtists();
	}

	renderSidebar() {
		if (this.props.auth) {
			if (this.props.auth.user) {
				return <Sidebar {...this.props} />;
			}
		}
		return false;
	}

	render() {
		if (this.props.sites.length === 0) return null;
		return (
			<div>
				<Sidebar {...this.props} />
				{React.cloneElement(this.props.children, this.props)}
			</div>
		);
	}
}

Home.propTypes = {
	auth: React.PropTypes.object,
	children: React.PropTypes.object,
	route: React.PropTypes.object,
	sites: React.PropTypes.array,
	fetchSites: React.PropTypes.func,
	fetchArtists: React.PropTypes.func,
	fetchPages: React.PropTypes.func,
};

// export default Home;


function mapStateToProps(state) {
	return {
		auth: state.auth,
		sites: state.sites,
		artists: state.artists,
	};
}

export default connect(mapStateToProps, { fetchSites, fetchArtists })(Home);
