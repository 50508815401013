function pages(state = [], action) {
	const data = action.data;
	let siteIndex;
	let pageIndex;
	let site;
	let newState;
	switch (action.type) {
	case 'add_page':
		newState = [
			...state,
			action.payload,
		];
		newState.sort((a, b) => {
			if (a.slug < b.slug) return -1;
			if (a.slug > b.slug) return 1;
			return 0;
		});
		return newState;
	case 'fetch_pages':
		newState = action.payload.pages;
		return newState;

	case 'update_page':
		siteIndex = state.findIndex((stateSite) => stateSite.slug === action.payload.slug);
		newState = [
			...state.slice(0, siteIndex),
			action.payload,
			...state.slice(siteIndex + 1),
		];
		return newState;
	default:
		return state;
	}
}

export default pages;
