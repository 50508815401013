function artists(state = [], action) {
	switch (action.type) {
	case 'UPDATE_ARTIST':
		return state;
	case 'ADD_ARTIST':
      return [ ...state, action.data ];
	case 'REMOVE_ARTIST':
		return state;
	default:
		return state;
	}
}

export default artists;
