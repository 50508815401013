import React from 'react';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/auth';

class Register extends React.Component {

	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.state = {
			values: {},
		};
	}

	onSubmit(e) {
		e.preventDefault();
		// const apiRoute = this.props.route.config.apiRoot;
		const data = this.state.values;
		this.props.registerUser(data, this.context.router);
	}

	handleFieldChange(id, value) {
		const values = Object.assign({}, this.state.values);
		values[id] = value;
		this.setState({ values });
	}

	renderAlert() {
		if (this.props.errorMessage) {
			return (
				<div>
					<span className="error">{this.props.errorMessage}</span>
				</div>
			);
		}
		return false;
	}

	render() {
		return (
			<div className="form modal">
				<h1>Register</h1>
				<form onSubmit={this.onSubmit} >
					{this.renderAlert()}
					<fieldset>
						<InputField name="name" label="Name" onChange={this.handleFieldChange} value={this.state.values.name} />
						<InputField name="username" label="Username" onChange={this.handleFieldChange} value={this.state.values.username} />
						<InputField name="email" label="Email" onChange={this.handleFieldChange} value={this.state.values.email} />
						<InputField name="password" type="password" label="Password" onChange={this.handleFieldChange} value={this.state.values.password} />
						<InputField name="password2" type="password" label="Confirm Password" onChange={this.handleFieldChange} value={this.state.values.password2} />
					</fieldset>
					<button type="submit">Register</button>
				</form>
			</div>
		);
	}
}

Register.propTypes = {
	params: React.PropTypes.object,
	route: React.PropTypes.object,
	errorMessage: React.PropTypes.string,
	registerUser: React.PropTypes.func,
};

Register.contextTypes = {
	router: React.PropTypes.object,
};

//

class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.props.onChange(this.props.name, e.target.value);
	}

	render() {
		const type = this.props.type || 'text';
		return (
			<div className="form-field">
				<label htmlFor={this.props.name}>{this.props.label}</label>
				<input type={type} name={this.props.name} value={this.props.value || ''} onChange={this.handleChange} />
			</div>
		);
	}
}

InputField.propTypes = {
	type: React.PropTypes.string,
	name: React.PropTypes.string,
	label: React.PropTypes.string,
	value: React.PropTypes.string,
	onChange: React.PropTypes.func,
};

function mapStateToProps(state) {
	return {
		errorMessage: state.auth.error,
		message: state.auth.message,
		authenticated: state.auth.authenticated,
	};
}

export default connect(mapStateToProps, { registerUser })(Register);
