import { getData, postData, putData, patchData, deleteData } from './ajax';

// getData(action, errorType, isAuthReq, url, dispatch) {
// postData(action, errorType, isAuthReq, url, dispatch, data) {


export function createPage(data, params, successCallback) {
	const url = `/sites/${params.siteSlug}/createPage`;
	return (dispatch) => postData('update_site', 'page_error', true, url, dispatch, data, successCallback);
}

export function updatePage(data, params, successCallback) {
	const url = `/sites/${params.siteSlug}/${params.pageSlug}`;
	return (dispatch) => patchData('update_site', 'page_error', true, url, dispatch, data, successCallback);
}

export function deletePage(data, params, successCallback) {
	const url = `/sites/${params.siteSlug}/${params.pageSlug}`;
	return (dispatch) => deleteData('update_site', 'page_error', true, url, dispatch, successCallback);
}