import React from 'react';
// import update from 'react-addons-update';
// import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../actions/actionCreators.js';

import Sidebar from './Sidebar.js';

class Main extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="app">
				<main>
					{this.props.children}
				</main>
			</div>
		);
	}
}

					// {React.cloneElement(this.props.children, this.props)}
Main.propTypes = {
	auth: React.PropTypes.object,
	children: React.PropTypes.object,
	route: React.PropTypes.object,
};

// export default Main;


function mapStateToProps(state) {
	return {
		auth: state.auth,
		sites: state.sites,
		artists: state.artists,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
