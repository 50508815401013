import axios from 'axios';
import cookie from 'react-cookie';
import { logoutUser } from './auth';
import { STATIC_ERROR, FETCH_USER } from './types';
import config from '../config.js';

const API_URL = config.apiRoot;
// const CLIENT_ROOT_URL = config.appRoot;

// TODO: WHY is this in the ajax file and not errors ???
export function errorHandler(dispatch, error, type) {
	console.warn(type, error, error.message, error.response, Object.keys(error));
	let errorMessage = '';
	if (error.data) {
		errorMessage = (error.data.error) ? error.data.error : error.data;
	} else {
		errorMessage = error;
	}
	// NOT AUTHENTICATED ERROR
	if (error.status === 401) {
		errorMessage = 'You are not authorized to do this.';
	}

	dispatch({
		type,
		payload: errorMessage,
	});
}

// Post Request
export function postData(action, errorType, isAuthReq, url, dispatch, data, successCallback) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: cookie.load('token') } };
	}

	axios.post(requestUrl, data, headers)
	.then((response) => {
		console.log(response);
		successCallback().then(() => {
			dispatch({
				type: action,
				payload: response.data,
			});
		});
	})
	// .catch((error) => {
	// 	errorHandler(dispatch, error, errorType);
	// });
}


// Get Request
export function getData(action, errorType, isAuthReq, url, dispatch) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: cookie.load('token') } };
	}

	axios.get(requestUrl, headers)
	.then((response) => {
		dispatch({
			type: action,
			payload: response.data,
		});
	})
	// .catch((error) => {
	// 	errorHandler(dispatch, error, errorType);
	// });
}

// Put Request
export function putData(action, errorType, isAuthReq, url, dispatch, data) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: cookie.load('token') } };
	}

	axios.put(requestUrl, data, headers)
	.then((response) => {
		dispatch({
			type: action,
			payload: response.data,
		});
	})
	// .catch((error) => {
	// 	errorHandler(dispatch, error, errorType);
	// });
}

// Put Request

export function patchData(action, errorType, isAuthReq, url, dispatch, data, successCallback) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: cookie.load('token') } };
	}
	axios.patch(requestUrl, data, headers)
	.then((response) => {
		successCallback().then(() => {
			dispatch({
				type: action,
				payload: response.data,
			});
		});
	})
	// .catch((error) => {
	// 	errorHandler(dispatch, error, errorType);
	// });
}


// Delete Request
export function deleteData(action, errorType, isAuthReq, url, dispatch, successCallback) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: cookie.load('token') } };
	}

	axios.delete(requestUrl, headers)
	.then((response) => {
		successCallback().then(() => {
			dispatch({
				type: action,
				payload: response.data,
			});
		})
	})
	// .catch((error) => {
	// 	errorHandler(dispatch, error, errorType);
	// });
}
