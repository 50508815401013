const editArtist = {

	title: 'Add Artist',

	fieldsets: [
		{
			id: 'artist',
			label: 'Configuration',
			config: {
				method: 'post',
				endpoint: '/api/artists/{artistSlug}/updateContent',
				action: 'editArtist',
				redirect: '/',
			},
			fields: [
				{
					id: 'name',
					label: 'Name',
					role: 'admin',
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'slug',
					label: 'Slug',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'id',
					label: 'id#',
					classNames: ['field--half'],
					options: {
						readOnly: true,
					},
				},
				{
					id: 'homepage_id',
					label: 'Homepage',
					type: 'select',
					options: 'sites',
				},
				{
					id: 'contentSchema',
					label: 'JSON Schema',
					role: 'dev',
					type: 'code',
					classNames: ['field--large'],
					validate: {
						json: true,
					},
				},
			],
		},
	],
};

export default editArtist;
