import React from 'react';
// import update from 'react-addons-update';
// import { Link } from 'react-router';


class ArtistHome extends React.Component {

	constructor(props) {
		super(props);
		const artistIndex = this.props.artists.findIndex((s) => s.slug === this.props.params.artistSlug);
		const artist = this.props.artists[artistIndex];
		this.state = { artist };
	}

	render() {
		return (
			<h1>{this.state.artist.name}</h1>
		);
	}
}

ArtistHome.propTypes = {
	params: React.PropTypes.object,
	artists: React.PropTypes.array,
};

export default ArtistHome;
