import React from 'react';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
// import store from '../store';
// import * as actionCreators from '../actions/actionCreators.js';

import Form from './Form';
import Tab from './Tab';
import ErrorMessage from './ErrorMessage';
import schemas from '../schemas/schemas';
import * as h from '../helpers/helpers';

// We won't use these here, but will pass them as props to the Forms
import { createSite, updateSite } from '../actions/sites';
import { createPage, updatePage, deletePage } from '../actions/pages';

class Editor extends React.Component {

	constructor(props) {
		super(props);
		this.renderMenu = this.renderMenu.bind(this);
		this.getActiveItem = this.getActiveItem.bind(this);
		this.renderForm = this.renderForm.bind(this);
		this.switchCategory = this.switchCategory.bind(this);
		this.resetErrors = this.resetErrors.bind(this);
		this.buildClassName = this.buildClassName.bind(this);
	}

	componentWillMount() {
		const activeItem = this.getActiveItem(this.props);
		const topItem = this.getTopItem(this.props);
		this.setState({
			currentTab: 0,
			errors: false,
			activeItem,
			topItem,
		});
	}

	componentWillReceiveProps(nextProps) {
		const activeItem = this.getActiveItem(nextProps);
		const currentTab = (this.props.location === nextProps.location) ? this.state.currentTab : 0;
		const topItem = this.getTopItem(nextProps);
		this.setState({
			currentTab,
			activeItem,
			topItem,
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props === nextProps && this.state === nextState) return false;
		return true;
	}

	getTopItem(props = this.props) {
		const route = [...props.routes].splice(1).reduce((previous, current) => {
			const a = (previous.path) ? previous.path : previous;
			return `${a}/${current.path}`;
		});
		if (route == 'dashboard/:modelOne/add') {
			const addItem = {};
			addItem.cmsTitle = 'Add'
			return addItem;
		}
		const params = props.params;
		const item = props[params.modelOne].find((m) => m.slug === params.slugOne);
		return item;
	}

	getActiveItem(props = this.props) {
		// The goal here is to get the active item's CONTENTSOURCE and SCHEMA based on the PARAMS.
		// The SCHEMA will be a the default schema merged with any additional schema supplied in the CONTENTSOURCE
		// Be sure to use "props" and not "this.props" in here -
		// Lifecycle components sometimes pass in incoming props
		const route = [...props.routes].splice(1).reduce((previous, current) => {
			const a = (previous.path) ? previous.path : previous;
			return `${a}/${current.path}`;
		});
		const activeItem = {};
		if (route == 'dashboard/:modelOne/add') {
			activeItem.site = {};
			activeItem.fieldSets = [];
			activeItem.contentSource = {};
			activeItem.schema = schemas[props.params.modelOne].create;
			this.setState({ activeItem });
			return activeItem;
		}


		const { params } = Object.assign({}, props);
		const { modelOne, slugOne, modelTwo, slugTwo } = params;
		// find the CONTENT based on the params
		const one = props[modelOne].find((m) => m.slug === slugOne);
		let two = undefined;
		if (modelTwo) {
			two = one[modelTwo].find((m) => m.slug === slugTwo);
		}

		activeItem.contentSource = two || one;

		// find default SCHEMA
		let schemaModel = undefined;
		let schemaSlug = undefined;
		if (modelTwo) {
			schemaModel = modelTwo;
			schemaSlug = slugTwo;
		} else {
			schemaModel = modelOne;
			schemaSlug = slugOne;
		}
		// if the slug doesn't match, use the schema's .default
		const defaultSchema = schemas[schemaModel][schemaSlug] || schemas[schemaModel].default;

		// merge default and additional schemas
		let additionalSchema = [];
		activeItem.schema = Object.assign({}, defaultSchema);
		if (activeItem.contentSource) {
			if (activeItem.contentSource.contentSchema) {
				additionalSchema = h.json.parse(activeItem.contentSource.contentSchema);
				if (additionalSchema.fieldsets) {
					activeItem.schema.fieldsets = [
						...additionalSchema.fieldsets,
						...defaultSchema.fieldsets,
					];
				}
			}
		}

		return activeItem;
	}

	switchCategory(index) {
		this.setState({ currentTab: index });
	}

	resetErrors() {
		this.setState({ errors: false });
		this.removeClass('thinking success');
	}

	buildClassName() {
		const classNames = ['editor'];
		if (this.state.classNames) classNames.push(...this.state.classNames);
		if (!this.props.params.pageSlug && !this.props.params.artistSlug) classNames.push('editor--site');
		return h.cNames(classNames.unique());
	}

	renderForm(formSchema, index) {
		const actions = {
			createSite: this.props.createSite,
			createPage: this.props.createPage,
			updateSite: this.props.updateSite,
			updatePage: this.props.updatePage,
			deletePage: this.props.deletePage,
		};
		let formContent = {};
		if (formSchema.config.rootObject === true) {
			formContent = this.state.activeItem.contentSource;
		} else {
			formContent = this.state.activeItem.contentSource.content[formSchema.id] || {};
		}
		if (formSchema.config.empty === true) formContent = {};

		return (
			<Form
				key={index}
				formSchema={formSchema}
				formContent={formContent}
				params={this.props.params}
				activeItem={this.state.activeItem}
				isActiveTab={(index === this.state.currentTab)}
				actions={actions}
				auth={this.props.auth}
			/>
		);
	}

	renderMenu() {
		const tabs = [];
		const fieldsets = this.state.activeItem.schema.fieldsets;
		const params = this.props.params;
		fieldsets.map((fieldset, index) => {
			const active = (index === this.state.currentTab);
			const options = fieldset.options || {};
			if (options.role && !h.userHasPermission(options.role, this.props.auth.user)) return false;
			tabs.push(<Tab options={options} key={index} index={index} active={active} fieldset={fieldset} handleClick={this.switchCategory} />);
		});
		const title = this.state.topItem.cmsTitle;
		const titleHref = `/dashboard/${params.modelOne}/${params.slugOne}`;
		return (
			<div className="menu">
				<div className="menu__title">
					<h1><Link to={titleHref}>{title}</Link></h1>
				</div>
				<div className="submenu submenu--categories">
					{tabs}
				</div>
			</div>
		);
	}

	// renderTabs(fieldset, index) {
	// 	const active = (index === this.state.currentTab);
	// 	return <Tab key={index} index={index} active={active} fieldset={fieldset} handleClick={this.switchCategory} />;
	// }

	renderMessages() {
		const errors = this.state.errors;
		if (errors) {
			return <ErrorMessage key={Date.now()} onClickHandler={this.resetErrors} type="error" errors={errors} />;
		}
		return null;
	}

	render() {
		const formSchemas = this.state.activeItem.schema.fieldsets;
		const className = this.buildClassName();
		return (
			<div className={className}>
				{this.renderMessages()}
				{this.renderMenu()}
				<div className="forms">
					{formSchemas.map(this.renderForm)}
				</div>
			</div>
		);
	}
}

Editor.contextTypes = {
	router: React.PropTypes.object.isRequired,
};

Editor.propTypes = {
	routes: React.PropTypes.array,
	user: React.PropTypes.object,
	artists: React.PropTypes.array,
	params: React.PropTypes.object,
	sites: React.PropTypes.array,
	location: React.PropTypes.object,
};

function mapStateToProps(state) {
	return {
		sites: state.sites,
		artists: state.artists,
	};
}

// function mapDispatchToProps(dispatch) {
// 	return bindActionCreators(actionCreators, dispatch);
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Editor);

export default connect(mapStateToProps, { createSite, createPage, updateSite, updatePage, deletePage })(Editor);
