function errors(state = [], action) {
	let error = {};
	switch (action.type) {
	case 'page_error':
		error = {
			type: action.type,
			// message: action.payload.response.data.error,
		};
		return [
			...state,
			error,
		];

	case 'site_error':
		if (action.payload.response) {
			error = {
				type: action.type,
				status: action.payload.response.status,
				// message: action.payload.response.data.error,
			};
		} else {
			error = {
				type: action.type,
				status: action.payload.status,
				message: action.payload.message,
			};
		}
		return [
			...state,
			error,
		];
	case 'auth_error':
		error = {
			type: action.type,
			status: action.payload.status,
			message: action.payload.message,
		};
		return [
			...state,
			error, // uncomment to show error message when redirected 
		];
	case 'clear_errors':
		return [];
	default:
		return state;
	}
}

export default errors;
