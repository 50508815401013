import assign from 'assign-deep';
import meta from './_meta';

const pageMeta = assign({}, meta);
pageMeta.config.action = 'updatePage';
// set the title to full width and remove the canonical field
pageMeta.fields.find(f => f.id === 'title').classNames = [];
// add some info for the Page meta
const pageInfo = {
	id: 'pageMetaInfo',
	type: 'section',
	label: 'Page Meta settings',
	help: 'Sitewide meta info should be added to the site settings. Add page-specific meta info here.',
};
pageMeta.fields.unshift(pageInfo);
// remove canonical from Page settings
const canonicalField = pageMeta.fields.findIndex(f => f.id === 'canonical');
pageMeta.fields.splice(canonicalField, 1);

const editPage = {

	title: 'Page Settings',

	fieldsets: [
		pageMeta,
		{
			id: 'siteConfig',
			label: 'Config',
			config: {
				rootObject: true,
				method: 'patch',
				action: 'updatePageSettings',
			},
			fields: [
				{
					id: 'cmsTitle',
					label: 'CMS Title',
					role: 'admin',
					validate: {
						minLength: 3,
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'slug',
					label: 'Slug',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'template',
					label: 'Template',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						alphaDash: true,
					},
				},
				{
					id: 'contentSchema',
					label: 'JSON Schema',
					role: 'dev',
					type: 'code',
					classNames: ['field--large'],
					validate: {
						json: true,
					},
				},
				{
					id: '_id',
					label: 'id#',
					classNames: ['field--half'],
					options: {
						readOnly: true,
					},
				},
			],
		},
		{
			id: 'delete',
			label: 'Delete Page',
			options: {
				classNames: ['secondary'],
				role: 'dev',
			},
			config: {
				redirect: '/dashboard/sites/{slugOne}/',
				method: 'delete',
				action: 'deletePage',
			},
			fields: [
				{
					id: 'delete',
					value: 'Delete Page',
					classNames: ['delete'],
					type: 'submit',
					options: {
						changeMethod: 'delete',
						confirm: true,
					},
				},
			],
		},
	],
};


export default editPage;
