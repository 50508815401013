const meta = {
	id: 'meta',
	label: 'Meta & OpenGraph',
	config: {
		method: 'patch',
	},
	fields: [
		{
			id: 'title',
			label: 'Title',
			role: 'admin',
			classNames: ['field--half'],
			validate: {
				maxLength: 48,
			},
		},
		{
			id: 'canonical',
			label: 'Canonical URL',
			classNames: ['field--half'],
			role: 'admin',
			validate: {
				url: true,
				required: true,
			},
		},
		{
			id: 'favicon',
			label: 'Favicon',
			role: 'admin',
			type: 'image',
			classNames: ['field--favicon'],
			validate: {
				imageUrl: true,
			},
			options: {
				showImage: true,
			},
		},
		{
			id: 'meta_description',
			label: 'Meta Description',
			role: 'admin',
			type: 'textarea',
		},
		{
			id: 'meta_tags',
			label: 'Meta Tags',
			role: 'admin',
		},
		{
			id: 'og_description',
			label: 'OpenGraph Description',
			type: 'textarea',
			role: 'admin',
		},
		{
			id: 'og_image',
			label: 'OpenGraph Image',
			role: 'admin',
			type: 'image',
			classNames: ['field--ogImage'],
			validate: {
				imageUrl: true,
			},
			options: {
				showImage: true,
			},
		},
		{
			id: 'additional_head',
			label: 'Header Scripts',
			classNames: ['field--large'],
			role: 'admin',
			type: 'code',
			help: 'Google Analytics, Google Tag Manager',
		},
		{
			id: 'additional_body',
			label: 'Body Scripts',
			classNames: ['field--large'],
			role: 'admin',
			type: 'code',
			help: 'Google Tag Manger (noscript)',
		},
		{
			id: 'additional_footer',
			label: 'Footer Scripts',
			classNames: ['field--large'],
			role: 'admin',
			type: 'code',
			help: 'Remarketing, Facebook pixel',
		},
	],
};

export default meta;
