import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { userHasPermission } from '../helpers/helpers.js';
import { protectedTest } from '../actions/auth';


class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.props.protectedTest();
	}

	renderLink(prefix, page) {
		return <li key={page.slug}><Link to={`${prefix}${page.slug}`}><h4>{page.cmsTitle || page.name}</h4></Link></li>;
	}

	renderIfRole(role, href, text) {
		if (userHasPermission('dev', this.props.auth.user)) {
			return <li><Link to={href}><h5>{text}</h5></Link></li>;
		}
		return null;
	}

	render() {
		return (
			<div className="dashboard">
				<div className="home">
					<h1>LuckyMe</h1>
					<div className="categories">
						<div className="category">
							<h4 className="category__title">Sites</h4>
							<ul>
								{this.props.sites.map(this.renderLink.bind(null, '/dashboard/sites/'))}
								{this.renderIfRole('dev', '/dashboard/sites/add', '+ add site')}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


Dashboard.propTypes = {
	auth: React.PropTypes.object,
	sites: React.PropTypes.array,
	artists: React.PropTypes.array,
	route: React.PropTypes.object,
	protectedTest: React.PropTypes.func,
};

function mapStateToProps(state) {
	return {
		sites: state.sites,
	};
}

export default connect(mapStateToProps, { protectedTest })(Dashboard);
