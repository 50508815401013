import assign from 'assign-deep';
import meta from './_meta';

const siteMeta = assign({}, meta);
siteMeta.config.action = 'updateSite';

const editSite = {

	title: 'Site Settings',

	fieldsets: [
		{
			id: 'general',
			label: 'General',
			config: {
				action: 'updateSiteSettings',
				rootObject: true,
			},
			fields: [
				{
					id: 'homepage',
					label: 'Current Homepage',
					type: 'select',
					options: 'sitePages',
					validate: {
						required: true,
					},
				},
			],
		},
		siteMeta,
		{
			id: 'siteConfig',
			label: 'CMS Settings',
			config: {
				rootObject: true,
				action: 'updateSiteSettings',
				endpoint: '/api/sites/{siteSlug}/update',
			},
			fields: [
				{
					id: 'cmsTitle',
					label: 'CMS Title',
					role: 'admin',
					validate: {
						minLength: 3,
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'slug',
					label: 'Slug',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: '_id',
					label: 'id#',
					classNames: ['field--half'],
					options: {
						readOnly: true,
					},
				},
				{
					id: 'contentSchema',
					label: 'JSON Schema',
					role: 'dev',
					type: 'code',
					classNames: ['field--large'],
					validate: {
						json: true,
					},
				},
			],
		},
	],
};

export default editSite;
