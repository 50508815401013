import axios from 'axios';

export function tourDates() {
	const self = {};
	let props = {};

	function getDates() {
		const siblings = props.fieldset.props.fields;
		const idField = siblings.find((f) => f.id === 'id');
		const apiSourceField = siblings.find((f) => f.id === 'apiSource');
		const id = idField.value;
		const apiSource = (apiSourceField.value) ? apiSourceField.value : Object.keys(apiSourceField.options)[0];
		const host = (window.location.hostname === 'localhost') ? 'localhost' : 'api.luckyme.net';
		const key = 'Qye38eD6MD2BU844Ryw32fi8';
		const params = { key, id };
		return axios.get(`http://${host}:3001/api/resources/${apiSource}`, { params });
	}

	self.build = function build() {
		props = this.props;
		getDates().then((response) => {
			const dates = response.data;
			for (const dateSource of dates) {
				const date = {};
				const sourceDateTime = new Date(dateSource.datetime);
				date.dateID = dateSource.id;
				date.sourceDate = `${sourceDateTime.getDate()}/${sourceDateTime.getMonth() + 1}/${sourceDateTime.getFullYear()}`;
				date.sourceVenue = dateSource.venue.name;
				date.sourceCity = dateSource.venue.city;
				date.sourceRegion = dateSource.venue.region;
				this.addField(date);
			}
		}).catch((error) => {
			console.log(error, error.message, error.config);
		});
	};

	return self.build;
}
