// **
// **  To Do
/* **********************

	- integrate facebook scrape API
	- Favicon field
	- AWS integration?

	- REDUX: editing updates global store.

	- Map content to fields
	- Better error handling for 500s
	- Make ParseEndPoint smart by looking at available params
	- smarter AddClass / RemoveClass in Form.js (should resolve bug where 'form--unsaved' class is removed after a form save and store update)

*/

// **
// **  Imports
// **********************

import React from 'react';
import { render } from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { syncHistoryWithStore } from 'react-router-redux';
import cookie from 'react-cookie';

import rootReducer from './reducers/reducers.js';

// **
// **  Helpers
// **********************
import './helpers/prototypes';

// **
// **  Redux
// **********************

const defaultState = {
	auth: {},
	sites: [],
	artists: [],
};

const enhancers = compose(
	window.devToolsExtension ? window.devToolsExtension() : f => f
);

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(rootReducer, defaultState, enhancers);
const history = syncHistoryWithStore(browserHistory, store);

const token = cookie.load('token');

if (token) {
	store.dispatch({ type: 'auth_user', payload: cookie.load('user') });
}

// **
// **  Routing
// **********************

import routes from './routes';

render(
	<Provider store={store}>
		<Router history={history} routes={routes} />
	</Provider>,
	document.getElementById('root')
);

