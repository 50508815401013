import React from 'react';
// import update from 'react-addons-update';
// import { Link } from 'react-router';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as actionCreators from '../actions/actionCreators.js';

import { userHasPermission, cNames } from '../../helpers/helpers';
import { Select, Thumbnail, Checkbox } from './fieldtypes.js';

class Field extends React.Component {

	constructor(props) {
		super(props);
		this.buildInput = this.buildInput.bind(this);
		this.buildClassName = this.buildClassName.bind(this);
		this.getInputType = this.getInputType.bind(this);
		this.onChange = this.onChange.bind(this);
		this.getAttributes = this.getAttributes.bind(this);
	}

	onChange(input) {
		// in most cases the field sends in the entire event, so we want e.target.value.
		// in others (like a checkbox) this is needs some logic beforehand, so a simple value is sent.
		const value = (input.target) ? input.target.value : input;
		this.props.changeHandler(value, this.props.tree);
	}

	getInputType() {
		const type = this.props.field.type;
		switch (type) {
		case undefined:
			return 'input';
		case 'code':
			return 'textarea';
		case 'image':
			return 'input';
		case 'video':
			return 'input';
		default:
			return type;
		}
	}

	getAttributes() {
		const field = this.props.field;
		if (!field.options) return null;
		const attrs = [];
		if (field.options.readOnly || !userHasPermission(field.role, this.props.auth.user)) attrs.readOnly = 'readonly';
		return attrs;
	}

	buildInput() {
		const field = this.props.field;
		const InputType = this.getInputType();
		const attrs = this.getAttributes();
		if (field.type === 'section') {
			return (
				<h4 className="section__text">{field.text}</h4>
				);
		}
		if (field.type === 'submit') {
			return <input type="submit" value={field.value} />;
		}
		if (field.type === 'select') {
			// if (typeof(field.options) === 'object' && Array.isArray(field.options)) options.push(...field.options);
			if (field.options === 'sitePages') {
				// probably better to make a globally available 'get pages by site id' function
				field.options = [];
				if (this.props.activeItem.contentSource) {
					console.log(field)
					for (const page of this.props.activeItem.contentSource.pages) {
						// const option = { value: page.slug, label: page.cmsTitle };
						field.options[page.slug] = page.cmsTitle;
					}
				}
			} else if (field.options === 'sites') {
				field.options = [];
				field.options.null = 'none selected';
				for (const site of this.props.sites) {
					// const option = { value: site.id, label: site.cmsTitle };
					field.options[site.id] = site.cmsTitle;
				}
			}
			return <Select onChange={this.onChange} field={field} {...attrs} />;
		}

		if (field.type === 'image') {
			return (
				<div className="input__wrapper">
					<InputType value={field.value} onChange={this.onChange} {...attrs} />
					<Thumbnail src={field.value} alt={field.label} />
				</div>
			);
		}
		if (field.type === 'checkbox') {
			return <Checkbox onChange={this.onChange} config={field.config} value={field.value} id={field.id} fieldIndex={this.props.fieldIndex} />;
		}
		return <InputType value={field.value} onChange={this.onChange} {...attrs} />;
	}

	buildClassName() {
		const field = this.props.field;
		const classNames = ['field'];
		if (field.type) classNames.push(`field--${field.type}`);
		if (field.classNames) classNames.push(...field.classNames);
		// Modify based on options
		if (field.options) {
			const options = field.options;
			if (options.hidden) classNames.push('hidden');
			if (options.showImage) classNames.push('field--withImage');
			if (options.readOnly) classNames.push('field--readOnly');
		}

		// Modify based on permission levels
		if (!userHasPermission(field.role, this.props.auth.user)) classNames.push('field--readOnly');

		// Modify based on properties
		if (!field.unchanged) classNames.push('field--changed');
		if (!field.valid) classNames.push('field--invalid');
		if (field.type === 'code') classNames.push('field--code');
		if (field.type === 'image') classNames.push('field--withImage');
		if (field.type === 'checkbox') classNames.push('field--checkbox');
		if (this.props.disabled && field.config.disableFieldset !== true) classNames.push('field--disabled');
		return cNames(classNames.unique());
	}

	buildHelpText() {
		if (!this.props.field.help) return null;
		return (
			<h5 className="field__help">{this.props.field.help}</h5>
		);
	}

	renderFieldErrors() {
		const errors = this.props.field.errors;
		if (errors.passes !== false) return <h5>&nbsp;</h5>;
		const messages = [];
		Object.keys(errors).map((key) => {
			if (key === 'passes') return false;
			const error = errors[key];
			messages.push(error.message);
			return true;
		});
		console.log(messages);
		return <h5>{messages}</h5>;
	}

	render() {
		const field = this.props.field;
		return (
			<div className={this.buildClassName()} data-fieldId={field.id}>
				<label className="form__label" htmlFor={field.id} >{field.label}</label>
				{this.buildInput(field)}
				{this.buildHelpText()}
				<div className="field__errors">
					{this.renderFieldErrors()}
				</div>
			</div>
		);
	}
}

Field.propTypes = {
	fieldIndex: React.PropTypes.number,
	auth: React.PropTypes.object,
	sites: React.PropTypes.array,
	tree: React.PropTypes.array,
	params: React.PropTypes.object,
	field: React.PropTypes.object,
	disabled: React.PropTypes.bool,
	changeHandler: React.PropTypes.func,
	activeItem: React.PropTypes.object,
};

// function mapStateToProps(state, ownProps) {
// 	console.log(ownProps);
// 	return {
// 		sites: state.sites,
// 		artists: state.artists,
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	console.log(dispatch);
// 	return bindActionCreators(actionCreators, dispatch);
// }

export default Field;

// export default connect(mapStateToProps, mapDispatchToProps)(Field);
