const tourDates = [
	{
		id: 'apiSource',
		label: 'API Source',
		type: 'select',
		options: {
			bandsintown: 'BandsInTown',
		},
		config: {
			required: true,
			default: 'bandsintown',
		},
	},
	{
		id: 'id',
		label: 'API Identifier',
	},
	{
		id: 'dates',
		type: 'structure',
		label: 'Dates',
		config: {
			addLabel: '+ Add Date',
			display: '{customVenue || sourceVenue} - {customCity || sourceCity}, {customRegion || sourceRegion} - {customDate || sourceDate}',
			build: 'tourDates',
		},
		structure: [
			{
				id: 'customVenue',
				classNames: ['field--half'],
				label: 'Custom Venue',
			},
			{
				id: 'sourceVenue',
				label: 'Source Venue',
				classNames: ['field--half'],
				options: {
					readOnly: true,
				},
			},
			{
				id: 'customCity',
				classNames: ['field--half'],
				label: 'Custom City',
			},
			{
				id: 'sourceCity',
				label: 'Source City',
				classNames: ['field--half'],
				options: {
					readOnly: true,
				},
			},
			{
				id: 'customRegion',
				classNames: ['field--half'],
				label: 'Custom Region',
			},
			{
				id: 'sourceRegion',
				label: 'Source Region',
				classNames: ['field--half'],
				options: {
					readOnly: true,
				},
			},
			{
				id: 'customDate',
				classNames: ['field--half'],
				label: 'Custom Date',
				validate: {
					date: true,
				},
			},
			{
				id: 'sourceDate',
				label: 'Source Date',
				classNames: ['field--half'],
				options: {
					readOnly: true,
				},
			},
			{
				id: 'dateID',
				label: 'ID',
				options: {
					hidden: true,
					readOnly: true,
				},
			},
		],
	},
];

export default tourDates;
