import React from 'react';
// import update from 'react-addons-update';
// import { Link } from 'react-router';
import { cNames } from '../helpers/helpers';

class Tab extends React.Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.props.handleClick(this.props.index);
	}

	render() {
		let classNames = ['submenu__item'];
		if (this.props.active) classNames.push['active'];
		if (this.props.options.classNames) classNames.push(...this.props.options.classNames);
		const className = cNames(classNames);
		return (<h4 className={className} onClick={this.onClick} >{this.props.fieldset.label}</h4>);
	}
}

Tab.propTypes = {
	index: React.PropTypes.number,
	handleClick: React.PropTypes.func,
	fieldset: React.PropTypes.object,
	active: React.PropTypes.bool,
};

export default Tab;
