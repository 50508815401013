const createSite = {

	title: 'Create Site',

	fieldsets: [
		{
			id: 'createSite',
			label: 'Configuration',
			config: {
				action: 'createSite',
				redirect: '/dashboard',
				rootObject: true,
				empty: true,
			},
			fields: [
				{
					id: 'cmsTitle',
					label: 'CMS Title',
					role: 'admin',
					validate: {
						minLength: 3,
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'slug',
					label: 'Slug',
					role: 'dev',
					classNames: ['field--half'],
					validate: {
						maxLength: 20,
						required: true,
					},
				},
				{
					id: 'id',
					label: 'id#',
					classNames: ['field--half'],
					options: {
						readOnly: true,
					},
				},
				{
					id: 'contentSchema',
					label: 'JSON Schema',
					role: 'dev',
					type: 'code',
					classNames: ['field--large'],
					validate: {
						json: true,
					},
				},
			],
		},
	],
};

export default createSite;
