import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Main from './components/Main';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Editor from './components/Editor';
import SiteHome from './components/SiteHome';
import ArtistHome from './components/ArtistHome';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import Register from './components/auth/Register';
import NotFoundPage from './components/NotFoundPage';

import config from './config';
import requireAuth from './components/auth/require_auth';
import * as actionCreators from './actions/actionCreators.js';

function mapStateToProps(state) {
	return {
		auth: state.auth,
		sites: state.sites,
		artists: state.artists,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(actionCreators, dispatch);
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default (
	<Route path="/" component={App} config={config} >
		<IndexRoute component={Login} config={config} />
		<Route path="/login" component={Login} config={config} />
		<Route path="/logout" component={Logout} />
		{/* <Route path="/register" component={Register} config={config} /> */}
		<Route path="dashboard" component={requireAuth(Home)} >
			<IndexRoute component={Dashboard} />
			<Route path=":modelOne/add" component={Editor} />
			<Route path=":modelOne/:slugOne">
				<IndexRoute component={SiteHome} />
				<Route path="settings" component={Editor} />
				<Route path=":modelTwo">
					<IndexRoute component={Editor} />
					<Route path="settings" component={Editor} />
					<Route path=":slugTwo" component={Editor} />
				</Route>
			</Route>
			<Route path="*" component={NotFoundPage} />
		</Route>
	</Route>
);

const oldRoutes = (
	<Route path="/" component={App} config={config} >
		<IndexRoute component={Login} config={config} />
		<Route path="/logout" component={Logout} />
		<Route path="/register" component={Register} config={config} />
		<Route path="dashboard" component={requireAuth(Home)} >
			<IndexRoute component={Dashboard} />
			<Route path="sites/add" component={Editor} />
			<Route path="sites/:siteSlug">
				<IndexRoute component={SiteHome} />
				<Route path="settings" component={Editor} />
				<Route path="createPage" component={Editor} />
				<Route path=":pageSlug" component={Editor} />
			</Route>
			<Route path="/artists/add" component={Editor} />
			<Route path="/artists/:artistSlug">
				<IndexRoute component={ArtistHome} />
				<Route path="/artists/:artistSlug/settings" component={Editor} />
			</Route>
			<Route path="*" component={NotFoundPage} />
		</Route>
	</Route>
);
