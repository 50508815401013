import React from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/auth';
import { fetchSites } from '../../actions/sites';

import ErrorMessage from '../ErrorMessage';

class Login extends React.Component {

	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.state = {
			values: {},
		};
	}

	componentWillMount() {
		if (this.props.auth.authenticated) {
			this.context.router.push('/dashboard');
		}
		this.props.fetchSites();
	}

	onSubmit(e) {
		e.preventDefault();
		// const apiRoute = this.props.route.config.apiRoot;
		const data = this.state.values;
		this.props.loginUser(data, this.context.router);

		// e.preventDefault();
		// // const apiRoute = this.props.route.config.apiRoot;
		// const data = this.state.values;
		// this.props.loginUser(data, this.context.router).then(() => {
		// 	router.push('/dashboard');

		// }).catch((error, b) => {
		// 	console.log(error, b)
		// });
	}

	resetErrors() {
		const auth = Object.assign({}, this.props.auth);
		auth.error = null;
		this.setState({ auth });
	}

	handleFieldChange(id, value) {
		const values = Object.assign({}, this.state.values);
		values[id] = value;
		this.setState({ values });
	}

	renderMessages() {
		const errors = this.props.errors;
		if (errors.length > 0) {
			return <ErrorMessage key={Date.now()} type="error" errors={errors} />;
		}
		return null;
	}

	renderAlert() {
		if (this.props.errorMessage) {
			return (
				<div>
					<span className="error">{this.props.errorMessage}</span>
				</div>
			);
		}
		return false;
	}

	render() {
		return (
			<div className="form modal">
				<h1>Login</h1>
				<form onSubmit={this.onSubmit} >
					{this.renderMessages()}
					<fieldset>
						<InputField name="email" label="Email" onChange={this.handleFieldChange} value={this.state.values.email} />
						<InputField name="password" type="password" label="Password" onChange={this.handleFieldChange} value={this.state.values.password} />
					</fieldset>
					<button type="submit">Login</button>
				</form>
			</div>
		);
	}
}

Login.propTypes = {
	errors: React.PropTypes.array,
	params: React.PropTypes.object,
	auth: React.PropTypes.object,
	route: React.PropTypes.object,
	errorMessage: React.PropTypes.string,
	loginUser: React.PropTypes.func,
};

Login.contextTypes = {
	router: React.PropTypes.object,
};

//


class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.props.onChange(this.props.name, e.target.value);
	}

	render() {
		const type = this.props.type || 'text';
		return (
			<div className="form-field">
				<label htmlFor={this.props.name}>{this.props.label}</label>
				<input type={type} name={this.props.name} value={this.props.value || ''} onChange={this.handleChange} />
			</div>
		);
	}
}

InputField.propTypes = {
	type: React.PropTypes.string,
	name: React.PropTypes.string,
	label: React.PropTypes.string,
	value: React.PropTypes.string,
	onChange: React.PropTypes.func,
};

function mapStateToProps(state) {
	return {
		auth: state.auth,
		errors: state.errors,
		// errorMessage: state.auth.error,
		// message: state.auth.message,
		// authenticated: state.auth.authenticated,
	};
}

export default connect(mapStateToProps, { loginUser, fetchSites })(Login);
