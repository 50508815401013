// Update this to api.luckyme.net after migration

// const apiRoot = (window.location.hostname === 'localhost') ? '//localhost:3001/api' : '//api.luckyme.net/api';
const apiRoot = "https://api.luckyme.net/api";

const config = {
  apiRoot
};

export default config;
