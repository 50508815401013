import React from 'react';
import Field from './Field';
import Structure from './Structure';
import { cNames } from '../../helpers/helpers.js';

class Fieldset extends React.Component {
	constructor(props) {
		super(props);
		this.renderField = this.renderField.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.checkForDisabled = this.checkForDisabled.bind(this);
	}

	componentWillMount() {
		this.checkForDisabled();
	}

	componentWillReceiveProps(nextProps) {
		this.checkForDisabled(nextProps);
	}

	checkForDisabled(props = this.props) {
		let disabled = false;
		for (const field of props.fields) {
			if (field.config.disableFieldset === true && field.value === 'on') disabled = true;
		}
		this.setState({ disabled });
	}

	changeHandler(newValue, tree) {
		if (this.props.changeHandler) {
			this.props.changeHandler(newValue, tree);
		}
		if (this.props.formChangeHandler) {
			this.props.formChangeHandler(newValue, tree);
		}
	}

	renderField(field, i) {
		let tree = [];

		if (this.props.tree) tree.push(...this.props.tree);
		if (field.type === 'structure') {
			tree = [...tree, i];
			const key = tree.join('-');
			const config = field.config;
			return (
				<Structure
					key={key}
					config={config}
					field={field}
					tree={tree}
					activeItem={this.props.activeItem}
					removeField={this.props.removeField}
					addField={this.props.addField}
					updateSortOrder={this.props.updateSortOrder}
					changeHandler={this.changeHandler}
					auth={this.props.auth}
					fieldset={this}
					form={this.props.form}
				/>
			);
		}

		tree = [...tree, i];
		const key = tree.join('-');
		return (
			<Field
				key={key}
				tree={tree}
				field={field}
				disabled={this.state.disabled}
				activeItem={this.props.activeItem}
				changeHandler={this.changeHandler}
				auth={this.props.auth}
				form={this.props.form}
			/>
		);
	}

	buildClassName() {
		const classes = ['fieldset'];
		// Shoot, sometimes we need to update the Subset, not the fieldset!
		// Maybe combine all of the HTML into the Fieldset component and use Subset only for passing props
		// for (const field of this.props.fields) {
		// 	if (field.type === 'checkbox' && field.value == 'on' && field.config.addClassNames) {
		// 		const newClasses = field.config.addClassNames.replace(',', '').split(' ');
		// 		classes.push(...newClasses);
		// 	}
		// }
		return cNames(classes);
	}

	render() {
		const className = this.buildClassName();
		return (
			<div className={className}>
				{this.props.fields.map(this.renderField)}
			</div>
		);
	}
}

Fieldset.propTypes = {
	tree: React.PropTypes.array,
	changeHandler: React.PropTypes.func,
	formChangeHandler: React.PropTypes.func,
	activeItem: React.PropTypes.object,
	removeField: React.PropTypes.func.isRequired,
	addField: React.PropTypes.func.isRequired,
	updateSortOrder: React.PropTypes.func.isRequired,
	auth: React.PropTypes.object,
	fields: React.PropTypes.array.isRequired,
};


export default Fieldset;
