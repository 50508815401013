import { UNAUTH_USER, AUTH_ERROR, FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, PROTECTED_TEST } from '../actions/types';

const INITIAL_STATE = { error: '', message: '', content: '', authenticated: false };

export default function (state = INITIAL_STATE, action) {
	let newState;
	switch (action.type) {
	case 'auth_error':
		newState = Object.assign({}, state);
		newState.authenticated = false;
		return newState;
	case 'auth_user':
		newState = Object.assign({}, state);
		newState.error = '';
		newState.message = '';
		newState.authenticated = true;
		newState.user = action.payload;
		return newState;
	case UNAUTH_USER:
		return { state, authenticated: false };
	case AUTH_ERROR:
		return { state, error: action.payload };
	case FORGOT_PASSWORD_REQUEST:
		return { state, message: action.payload.message };
	case RESET_PASSWORD_REQUEST:
		return { state, message: action.payload.message };
	case PROTECTED_TEST:
		return { state, content: action.payload.message };
	}
	return state;
}
