import format from 'string-template';
import React from 'react';
import Fieldset from './Fieldset';
import { cNames } from '../../helpers/helpers.js';
import * as b from '../../builders/builders.js';

class Structure extends React.Component {

	constructor(props) {
		super(props);
		this.addField = this.addField.bind(this);
		this.sort = this.sort.bind(this);
		this.renderSubSets = this.renderSubSets.bind(this);
		this.state = {
			classNames: [],
		};
	}

	componentWillMount() {
		if (this.props.config) {
			const classNames = this.buildClassNames(this.props);
			if (classNames) this.setState({ classNames });
		}
		if (this.props.config.build) {
			this.buildContent = b[this.props.config.build]();
			if (this.buildContent) this.buildContent();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.config) {
			const classNames = this.buildClassNames(nextProps);
			if (classNames) this.setState({ classNames });
		}
	}

	buildClassNames(props) {
		const classNames = ['field', 'field--structure'];
		if (props.config.classNames) classNames.push(...props.config.classNames);
		if (props.config.collapsible !== false) classNames.push('collapsible');
		return classNames;
	}

	addClass(string) {
		const input = string.split(' ');
		const classNames = this.state.classNames.concat(input).unique();
		this.setState({ classNames });
		return this; // allow chaining
	}

	toggleClass(string) {
		let classNames = [];
		const index = this.state.classNames.indexOf(string);
		if (index > -1) {
			classNames = [
				...this.state.classNames.slice(0, index),
				...this.state.classNames.slice(index + 1),
			];
		} else {
			classNames = [
				...this.state.classNames,
				string,
			];
		}
		this.setState({ classNames });
		return this;
	}

	removeClass(string) {
		const input = string.split(' ');
		const classNames = this.state.classNames.filter((className) => {
			for (const test of input) {
				if (test === className) return false;
			}
			return true;
		});
		this.setState({ classNames });
		return this;
	}

	addField(content) {
		this.props.addField(this.props.tree, content);
	}

	sort(index, direction) {
		const fields = this.props.field.fields.slice(0);
		const fieldToMove = fields[index];
		fieldToMove[0].unchanged = false;
		fields.splice(index, 1);
		const newIndex = (direction === 'up') ? index - 1 : index + 1;
		const updatedFields = [
			...fields.slice(0, newIndex),
			fieldToMove,
			...fields.slice(newIndex),
		];
		this.props.updateSortOrder(this.props.tree, updatedFields);
	}

	buildSubsetTitle(subset, config) {
		function mapValues(fields) {
			const values = {};
			for (const field of fields) {
				values[field.id] = field.value;
			}
			return values;
		}
		function isEmpty(obj) {
			const empty = Object.keys(obj).every((key) => obj[key] === '');
			return empty;
		}
		const fields = Array.isArray(subset) ? subset : subset.fields;
		const values = mapValues(fields);
		// If there's no config, use the first item
		const first = Object.keys(values).find((key) => {
			if (!values[key]) return false;
			if (values[key].length > 0) return values[key];
			return false;
		});

		// If the config offers OR options, rewrite the string.
		const optionRegex = /{([\w]*)\s?\|\|\s?([\w]*)}/g;
		let match = optionRegex.exec(config.display);
		let newDisplay = config.display;
		while (match !== null) {
			const options = match.slice(1);
			let hasValue = options.find((opt) => values[opt].length > 0);
			if (!hasValue) hasValue = options[0];
			newDisplay = newDisplay.replace(match[0], `{${hasValue}}`);
			match = optionRegex.exec(config.display);
		}

		const formattedString = (newDisplay) ? format(newDisplay, values) : values[first];
		if (isEmpty(values) || formattedString === '') return config.placeholder || 'New Item';
		return (formattedString);
	}

	renderAddButtons() {
		if (this.props.config.max <= this.props.field.fields.length) return null;
		const label = this.props.config.addLabel || '+ Add New';
		return (
			<div className="repeatable__add">
				<button onClick={this.addField} type="button"><h5>{label}</h5></button>
			</div>
		);
	}

	renderSubSets(subset, i) {
		const tree = [...this.props.tree, i];
		const fields = Array.isArray(subset) ? subset : subset.fields;
		const config = this.props.config;
		const title = this.buildSubsetTitle(subset, config);
		const siblingsCount = this.props.field.fields.length;
		// let title = fields.find((f) => f.id === config.display).value || '\xA0';

		return (
			<Subset
				key={i}
				config={this.props.config}
				title={title}
				fields={fields}
				fieldIndex={i}
				siblingsCount={siblingsCount}
				tree={tree}
				activeItem={this.props.activeItem}
				changeHandler={this.props.changeHandler}
				addField={this.props.addField}
				removeField={this.props.removeField}
				updateSortOrder={this.props.updateSortOrder}
				sort={this.sort}
				auth={this.props.auth}
			/>
		);
	}

	render() {
		const className = cNames(this.state.classNames);
		return (
			<div className={className}>
				<legend>
					<h3 className="legend__title">{this.props.field.label}</h3>
				</legend>
				{this.props.field.fields.map(this.renderSubSets)}
				{this.renderAddButtons()}
			</div>
		);
	}
}

Structure.propTypes = {
	config: React.PropTypes.object.isRequired,
	tree: React.PropTypes.array.isRequired,
	activeItem: React.PropTypes.object.isRequired,
	auth: React.PropTypes.object.isRequired,
	field: React.PropTypes.object.isRequired,
	changeHandler: React.PropTypes.func.isRequired,
	addField: React.PropTypes.func.isRequired,
	removeField: React.PropTypes.func.isRequired,
	updateSortOrder: React.PropTypes.func.isRequired,
};

class Subset extends React.Component {

	constructor(props) {
		super(props);
		this.toggleOpen = this.toggleOpen.bind(this);
		this.isEmpty = this.isEmpty.bind(this);
		this.removeField = this.removeField.bind(this);
		this.moveUp = this.moveUp.bind(this);
		this.moveDown = this.moveDown.bind(this);

		this.up = (<svg className="icon icon--up" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M62.37,58.3H1.93L30.42,5.7ZM12,52.3H51.7l-21-34.56Z"/></svg>);
		this.down = (<svg className="icon icon--down" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M33.58,58.3,1.63,5.7H62.07ZM12.3,11.7l21,34.56L52,11.7Z"/></svg>);

		this.state = { open: false };
	}

	componentWillMount() {
		if (this.isEmpty()) {
			this.setState({
				open: true,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.isEmpty(nextProps)) {
			this.setState({
				open: true,
			});
		}
	}

	toggleOpen() {
		if (this.isEmpty()) return false;
		const open = !this.state.open;
		this.setState({ open });
		return true;
	}

	moveUp() {
		this.props.sort(this.props.fieldIndex, 'up');
	}

	moveDown() {
		this.props.sort(this.props.fieldIndex, 'down');
	}

	isEmpty(props = this.props) {
		for (const field of props.fields) {
			if (field.value !== '' && field.type !== 'structure' && field.type !== 'submit') return false;
		}
		return true;
	}

	removeField() {
		this.props.removeField(this.props.tree);
	}

	renderIf(condition, jsx) {
		if (condition) return jsx;
		return null;
	}

	buildClassNames() {
		const classNames = ['field__subset'];
		if (this.state.open || this.isEmpty()) classNames.push('open');

		for (const field of this.props.fields) {
			if (field.type === 'checkbox' && field.value == 'on' && field.config.addClassNames) {
				const newClasses = field.config.addClassNames.replace(',', '').split(' ');
				classNames.push(...newClasses);
			}
		}

		return cNames(classNames);
	}

	render() {
		const className = this.buildClassNames();
		return (
			<div className={className}>
				<div className="icons">
					{this.renderIf(this.props.fieldIndex > 0, <span className="sort" onClick={this.moveUp}>{this.up}</span>)}
					{this.renderIf(this.props.fieldIndex < this.props.siblingsCount - 1, <span className="sort" onClick={this.moveDown}>{this.down}</span>)}
				</div>
				<div className="subset__title">
					<h3 className="title" onClick={this.toggleOpen}>{this.props.title}</h3>
					<h3 className="remove" onClick={this.removeField}>X</h3>
				</div>
				<Fieldset {...this.props} />
			</div>
		);
	}
}

Subset.propTypes = {
	title: React.PropTypes.string,
	fieldIndex: React.PropTypes.number,
	siblingsCount: React.PropTypes.number,
	removeField: React.PropTypes.func.isRequired,
	sort: React.PropTypes.func.isRequired,
	tree: React.PropTypes.array,
};


export default Structure;
