import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';

class Logout extends React.Component {
	componentWillMount() {
		this.props.logoutUser(this.context.router);
	}

	render() {
		return <div>You have been logged out.</div>;
	}
}

Logout.propTypes = {
	logoutUser: React.PropTypes.func,
};

Logout.contextTypes = {
	router: React.PropTypes.object,
};

export default connect(null, actions)(Logout);
