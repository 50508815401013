import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import sites from './sites';
import artists from './artists';
import pages from './pages';
import auth from './auth';
import errors from './errors';

const rootReducer = combineReducers({ sites, artists, pages, auth, errors, routing: routerReducer });

export default rootReducer;
