import React from 'react';
import update from 'react-addons-update';
// import { connect } from 'react-redux';

import Fieldset from './fields/Fieldset';
import ErrorMessage from './ErrorMessage';
import validator from '../helpers/validation.js';
import { cNames } from '../helpers/helpers.js';
import schemas from '../schemas/schemas';

// import { createSite, updateSite } from '../actions/sites';
// import { createPage, updatePage } from '../actions/pages';

class Form extends React.Component {
	constructor(props) {
		super(props);
		this.buildFieldsRecursive = this.buildFieldsRecursive.bind(this);
		this.buildClassNames = this.buildClassNames.bind(this);
		this.renderFormFields = this.renderFormFields.bind(this);
		this.submitFormData = this.submitFormData.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.validateField = this.validateField.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.onFormSuccess = this.onFormSuccess.bind(this);
		this.parseUrl = this.parseUrl.bind(this);
		this.addField = this.addField.bind(this);
		this.removeField = this.removeField.bind(this);
		this.updateSortOrder = this.updateSortOrder.bind(this);

		this.state = {
			fields: [],
			classNames: [],
		};
	}

	componentWillMount() {
		const fields = this.buildFieldsRecursive(this.props.formSchema.fields, this.props.formContent);
		this.setState({ fields });
	}

	componentWillReceiveProps(nextProps) {
		const fields = this.buildFieldsRecursive(nextProps.formSchema.fields, nextProps.formContent);
		this.setState({ fields });
	}

	onFormSuccess() {
		const success = new Promise((resolve, reject) => {
			setTimeout(() => {
				this.addClass('success');
			}, 800);
			setTimeout(() => {
				const redirect = (this.props.formSchema.config.redirect);
				if (redirect) {
					this.context.router.push(this.parseUrl(redirect));
				} else {
					this.removeClass('thinking success');
				}
				resolve();
			}, 1300);
		});
		return success;
	}

	findItemByTree(inputTree, originalFields = this.state.fields.slice(0)) {
		const baseTree = inputTree.slice(0);

		function findItem(tree, inputFields) {
			const index = tree.shift();
			const field = inputFields[index];
			if (tree.length > 0) {
				return findItem(tree, field.fields || field);
			}
			return inputFields[index];
		}
		return findItem(baseTree, originalFields);
	}

	addField(inputTree, content = null) {
		const baseTree = inputTree.slice(0);
		const originalFields = this.state.fields.slice(0);
		// recursively look in the objects for the field
		// (this should be a smarter, reusable function that is shared with this.handleFieldChange);
		function findStructure(tree, inputFields) {
			const index = tree.shift();
			const field = inputFields[index];
			// we look for greater than one because nested fields are part of an array, the structure is not
			if (tree.length > 0) {
				return findStructure(tree, field.fields || field);
			}
			return inputFields[index].structure;
		}

		function applyStructure(tree, inputFields, structure, buildFunction) {
			const index = tree.shift();
			const field = inputFields[index];
			if (tree.length > 0) {
				applyStructure(tree, field.fields || field, structure, buildFunction);
			} else {
				const newFields = buildFunction(structure, content);
				field.fields.push(newFields);
			}
		}

		// clone the tree for each function
		const structure = findStructure(baseTree.slice(0), originalFields);
		applyStructure(baseTree.slice(0), originalFields, structure, this.buildFieldsRecursive);
		// const fields = this.buildFieldsRecursive(originalFields, this.props.formContent);
		this.setState({ fields: originalFields });
	}

	updateSortOrder(inputTree, updatedFields) {
		const adjustedFields = this.state.fields.slice(0);
		const newField = this.findItemByTree(inputTree, adjustedFields);
		newField.fields = updatedFields;
		this.setState({ fields: adjustedFields });
	}

	removeField(inputTree) {
		const baseTree = inputTree.slice(0);
		const originalFields = this.state.fields.slice(0);

		function findField(tree, inputFields) {
			const index = tree.shift();
			const field = inputFields[index];
			if (tree.length > 0) {
				if (inputFields[index].fields) {
					inputFields[index].fields = findField(tree, field.fields);
				} else {
					inputFields[index] = findField(tree, field);
				}
				// inputFields[index] = findField(tree, field.fields || field);
				return inputFields;
			}
			return [
				...inputFields.slice(0, index),
				...inputFields.slice(index + 1),
			];
			// inputFields.splice(index, 0);
			// return inputFields;
		}

		const fields = findField(baseTree, originalFields);
		this.setState({ fields });
	}

	buildFieldsRecursive(fieldSourcesInput = [], formContent = []) {
		const fields = [];
		let fieldSources = fieldSourcesInput;
		// console.log(fieldSources);
		if (fieldSourcesInput.fixture) {
			fieldSources = schemas.fieldSchemas[fieldSourcesInput.fixture] || [];
		}
		for (const fieldSource of fieldSources) {
			const field = Object.assign({}, fieldSource);
			if (field.type === 'structure') {
				if (!field.fields) field.fields = [];
				// add on empty fields if there is content for them

				if (formContent[field.id]) {
					const appendLength = formContent[field.id].length - field.fields.length;
					for (let i = 0; i < appendLength; i++) {
						field.fields.push(field.structure);
					}
				}
				const newFields = [];
				field.fields.map((subset, i) => {
					const subcontent = formContent[field.id] || [];
					const newField = this.buildFieldsRecursive(subset, subcontent[i]);
					newFields.push(newField);
				});
				field.originalCount = field.fields.length;
				field.fields = newFields;
			} else {
				field.value = field.value || formContent[field.id] || '';
				field.originalValue = field.originalValue || field.value;
				field.valid = field.valid || true;
				field.errors = field.errors || {};
				field.unchanged = field.unchanged || true;
				field.options = field.options || {};
				field.config = field.config || {};
			}
			fields.push(field);
		}
		return fields;
	}

	handleFieldChange(newValue, baseTree) {
		const fields = this.state.fields;

		const validateField = this.validateField;
		function updateField(tree, inputFields) {
			const index = tree.shift();
			const field = inputFields[index];
			if (tree.length > 0) {
				// We're digging into the content recursively. If we're not at our last level, keep digging
				updateField(tree, field.fields || field);
			} else {
				field.value = newValue;
				field.unchanged = (newValue === field.originalValue);
				// if (newValue !== field.originalValue) field.unchanged = false;
				validateField(field);
			}
		}
		updateField(baseTree, fields);
		this.setState({ fields });
	}

	checkFieldsFor(property, value) {
		// Look through all fields to see if there are any instances of a particular value
		// (e.g. Are there any fields that have an 'unchanged: false' property?)
		function checkFieldsRecursive(fProperty, fValue, fields) {
			for (const field of fields) {
				if (Array.isArray(field)) {
					return checkFieldsRecursive(fProperty, fValue, field);
				} else if (field.type === 'structure') {
					// special handling for searching for unchanged value
					if (fProperty === 'unchanged' && (field.originalCount === field.fields.length) === fValue) return true;
					for (const subset of field.fields) {
						const check = checkFieldsRecursive(fProperty, fValue, subset);
						if (check) return check;
					}
				}
				if (field[fProperty] === fValue) return true;
			}
			return false;
		}
		return checkFieldsRecursive(property, value, this.state.fields);
	}

	validateField(field) {
		if (!field.validate) return false;
		const validation = validator.validate(field);
		if (validation.passes) {
			field.valid = true;
			field.errors = {};
		} else {
			field.valid = false;
			field.errors = validation;
		}
		return true; // implicit return
	}

	validateForm() {
		const values = {};
		const fields = this.state.fields;
		Object.keys(fields).forEach((key) => {
			if (fields[key].validate) {
				const errors = validator.validate(fields[key]);
				values[key] = {};
				if (!errors.passes) {
					values[key].valid = false;
					values[key].errors = errors;
				} else {
					values[key].valid = true;
					values[key].errors = {};
				}
			}
		});
		this.updateFieldState(values);
	}

	submitFormData(e) {
		e.preventDefault();
		this.addClass('thinking');
		const config = this.props.formSchema.config;
		const fields = this.state.fields;
		function fieldsToValues(fFields) {
			const values = {};
			for (const field of fFields) {
				if (Array.isArray(field)) {
					fieldsToValues(field);
					const subset = fieldsToValues(field);
					return subset;
				}
				if (field.type === 'structure') {
					if (!values[field.id]) values[field.id] = [];
					// const subsets = [];
					for (const subField of field.fields) {
						const subset = fieldsToValues(subField);
						values[field.id].push(subset);
					}
				} else {
					values[field.id] = field.value;
				}
			}
			return values;
		}
		const values = fieldsToValues(fields);

		const data = {};
		if (config.rootObject === true) {
			// some data needs to be placed on the root Object. (create site, site settings, etc)
			Object.keys(values).map((key) => {
				data[key] = values[key];
			});
		} else {
			data.content = {};
			data.content[this.props.formSchema.id] = values;
		}

		let action = config.action;
		let params = {};
		// console.log(action, this.props.params);
		switch (action) {
		case 'updateSite':
			params.siteSlug = this.props.params.slugOne;
			break;
		case 'updateSiteSettings':
			action = 'updateSite';
			params.siteSlug = this.props.params.slugOne;
			break;
		case 'createPage':
			params.siteSlug = this.props.params.slugOne;
			break;
		case 'updatePage':
			params.siteSlug = this.props.params.slugOne;
			params.pageSlug = this.props.params.slugTwo;
			break;
		case 'updatePageSettings':
			action = 'updatePage';
			params.siteSlug = this.props.params.slugOne;
			params.pageSlug = this.props.params.slugTwo;
			break;
		case 'deletePage':
			params.siteSlug = this.props.params.slugOne;
			params.pageSlug = this.props.params.slugTwo;
			break;
		default:
			params = null;
		}

		if (this.props.actions[action]) {
			this.props.actions[action](data, params, this.onFormSuccess);
		} else {
			console.warn(`${action} is not a valid action!`);
		}
	}

	parseUrl(string) {
		const params = this.props.params;
		let returnString = string;
		Object.keys(params).map((key) => {
			returnString = returnString.replace(`{${key}}`, params[key]);
		});
		return returnString;
	}

	buildClassNames() {
		const classes = ['editor-form', 'form--react'];
		classes.push(...this.state.classNames);
		if (this.props.isActiveTab) classes.push('active');
		if (this.checkFieldsFor('unchanged', false)) classes.push('form--unsaved'); // look for any false 'unchanged.valid'
		if (this.checkFieldsFor('valid', false)) classes.push('form--invalid'); // look for any false 'field.valid'
		if (this.state.disabled) classes.push('form--disabled');
		return cNames(classes);
	}

	addClass(string) {
		const input = string.split(' ');
		const classNames = this.state.classNames.concat(input).unique();
		this.setState({ classNames });
		return this;
	}

	removeClass(string) {
		const input = string.split(' ');
		const classNames = this.state.classNames.filter((className) => {
			for (const test of input) {
				if (test === className) return false;
			}
			return true;
		});
		this.setState({ classNames });
		return this;
	}

	renderFormFields() {
		const formId = this.props.formSchema.id;
		return (<Fieldset
			key={`form-${formId}`}
			formId={formId}
			fields={this.state.fields}
			activeItem={this.props.activeItem}
			addField={this.addField}
			removeField={this.removeField}
			formChangeHandler={this.handleFieldChange}
			updateSortOrder={this.updateSortOrder}
			auth={this.props.auth}
			form={this}
		/>);
	}

	render() {
		return (
			<form className={this.buildClassNames()} onSubmit={this.submitFormData}>
				{this.renderFormFields()}
				<div className="form__buttons">
					<button className="submit" type="submit">
						<span className="buttonText buttonText--default">Save</span>
						<span className="buttonText buttonText--thinking">
							<div className="dots">
								<div className="dot"></div>
								<div className="dot"></div>
								<div className="dot"></div>
							</div>
						</span>
						<span className="buttonText buttonText--success">✔</span>
					</button>
				</div>
			</form>
		);
	}
}

Form.contextTypes = {
	router: React.PropTypes.object.isRequired,
};

Form.propTypes = {
	errors: React.PropTypes.array,
	params: React.PropTypes.object,
	isActiveTab: React.PropTypes.bool,
	thinking: React.PropTypes.bool,
	successful: React.PropTypes.bool,
	fieldset: React.PropTypes.object,
	formSchema: React.PropTypes.object,
	formContent: React.PropTypes.object,
	activeItem: React.PropTypes.object,
	actions: React.PropTypes.object,
	auth: React.PropTypes.object,
};


// function mapStateToProps(state) {
// 	return {
// 		sites: state.sites,
// 		errors: state.errors,
// 	};
// }

export default Form;

// export default connect(mapStateToProps, { createSite, createPage, updateSite, updatePage })(Form);
