import cn from 'classnames';
import json5 from 'json5';
import deepmerge from 'deepmerge';

export const merge = deepmerge;

export const json = json5;

export function cNames(array) {
	return cn(array);
}

export function userHasPermission(required, user) {
	if (!required) return true;
	const levels = {
		root: 0,
		dev: 1,
		admin: 2,
		editor: 3,
	};
	const requiredLevel = levels[required];
	const userLevel = levels[user.role];
	if (userLevel <= requiredLevel) return true;
	return false;
}
