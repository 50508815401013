import { getData, postData, putData, patchData, deleteData } from './ajax';
import { ADD_SITE, UPDATE_SITE, REMOVE_SITE, FETCH_SITE, FETCH_SITES, SITE_ERROR } from './types';

// getData(action, errorType, isAuthReq, url, dispatch) {
// postData(action, errorType, isAuthReq, url, dispatch, data) {

export function createSite(data, params, successCallback) {
	return (dispatch) => {
		const url = '/sites/add';
		postData(ADD_SITE, SITE_ERROR, true, url, dispatch, data, successCallback);
	};
}

export function fetchSites() {
	const url = '/sites';
	return (dispatch) => getData(FETCH_SITES, SITE_ERROR, true, url, dispatch);
}

export function fetchSite(siteSlug) {
	const url = `/sites/${siteSlug}`;
	return (dispatch) => getData(FETCH_SITE, SITE_ERROR, true, url, dispatch);
}

export function updateSite(data, params, successCallback) {
	const url = `/sites/${params.siteSlug}`;
	return (dispatch) => patchData(UPDATE_SITE, SITE_ERROR, true, url, dispatch, data, successCallback);
}

export function deleteSite(siteSlug) {
	const url = `/sites/${siteSlug}`;
	return (dispatch) => deleteData(REMOVE_SITE, SITE_ERROR, true, url, dispatch);
}

