import * as h from './helpers.js';


const validator = {

	validate(field) {
		const results = { passes: true };
		const validators = field.validate;
		if (!field.value) field.value = ''; // Don't validate when there isn't anything to validate
		if (!validators.required && !field.value.length) return results; // Don't bother validating non-required empty fields
		if (field.disabled) return results; // Don't bother validating disabled fields (they won't be submitted either)
		const validatorsArray = Object.keys(validators); // make the array first so we can make 'required' the first rule to be checked
		if (validatorsArray.indexOf('required') !== -1) {
			// if there is a 'required' validator, move it to the beginning of the array so it is checked first
			const index = validatorsArray.indexOf('required');
			const req = validatorsArray[index];
			validatorsArray.splice(index, 1);
			validatorsArray.unshift(req);
		}

		validatorsArray.map((key) => {
			const options = validators[key];
			if (typeof(this.checkFor[key]) !== 'function') {
				console.log(`${key} is not a validator function`);
			}
			if (results.passes) {
				results[key] = (this.checkFor[key](field, options));
			} else {
				return false; // stop on the first error
			}
			if (!results[key].passes) results.passes = false; // set to false so we stop next time
			return true;
		});
		return results;
	},

	regExes: {
		alphaDash: /^[a-z-_]+$/i,
		alphaNum: /^[a-z0-9]+$/i,
		alphaNumDash: /^[a-z0-9-_]+$/i,
		date: /\d{1,2}\/\d{1,2}\/\d{4}/,
		decimal: '^\\d*\\.?\\d{1,N}$', // a string because we'll add the N value later, then turn into regex
		integer: /^[0-9]+$/i,
		email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
		url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
		imageUrl: /(^https?:\/\/)(?:[a-z0-9\-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:jpg|jpeg|gif|png)$/,
		youtube: /^https?:\/\/(?:www\.)?youtube.com\/watch\?(?=.*v=\w+)(?:\S+)?$/,
		videoUrl: /(^https?:\/\/)(?:[a-z0-9\-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:webm|ogg|mp4)$/,
	},

	checkFor: {

		alphaDash(field) {
			const result = { passes: true };
			if (!validator.regExes.alphaDash.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be letters, underscores or hyphens only`;
			}
			return result;
		},

		alphaNum(field) {
			const result = { passes: true };
			if (!validator.regExes.alphaNum.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be letters or numbers only`;
			}
			return result;
		},

		alphaNumDash(field) {
			const result = { passes: true };
			if (!validator.regExes.alphaNumDash.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be letters, numbers, underscores or hyphens only`;
			}
			return result;
		},

		date(field) {
			const result = { passes: true };
			if (!validator.regExes.date.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be a valid date, DD/MM/YYYY`
			}
			return result;
		},

		integer(field) {
			const result = { passes: true };
			if (!validator.regExes.integer.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be an integer`;
			}
			return result;
		},

		decimal(field, places = 2) {
			const result = { passes: true };
			const regExp = new RegExp(validator.regExes.decimal.toString().replace('{1,N}', `{1,${places}}`));
			console.log(regExp, field.value, regExp.test(field.value))
			if (!regExp.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be a number with no more than ${places} decimal places.`;
			}
			return result;
		},

		email(field) {
			const result = { passes: true };
			if (!validator.regExes.email.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be valid email address`;
			}
			return result;
		},

		imageUrl(field) {
			const result = { passes: true };
			if (!validator.regExes.imageUrl.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be a full, valid URL of an image (include 'http://').`;
			}
			return result;
		},

		json(field) {
			const result = { passes: true };
			try {
				h.json.parse(field.value);
			} catch (e) {
				result.passes = false;
				result.message = `${field.label} is not valid JSON`;
			}
			return result;
		},

		maxLength(field, maxLength) {
			const result = { passes: true };
			if (field.value.length > maxLength) {
				result.passes = false;
				result.message = `${field.label} must be less than ${maxLength} characters long"`;
			}
			return result;
		},

		minLength(field, minLength) {
			const result = { passes: true };
			if (field.value.length < minLength) {
				result.passes = false;
				result.message = `${field.label} must be less than ${minLength} characters long`;
			}
			return result;
		},

		required(field) {
			const result = { passes: true };
			if (field.value.length === 0) {
				result.passes = false;
				result.message = `${field.label} is required`;
			}
			return result;
		},

		url(field) {
			const result = { passes: true };
			if (!validator.regExes.url.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be a full, valid URL (include 'http://').`;
			}
			return result;
		},

		youtube(field) {
			const result = { passes: true };
			if (!validator.regExes.youtube.test(field.value)) {
				result.passes = false;
				result.message = `${field.label} must be youtube video URL (include 'http://').`;
			}
			return result;
		},

	},
};

export default validator;
